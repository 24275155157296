// Entry point for the build script in your package.json
import "./controllers";
import "./src/clipboard";
import "./src/turbo_native/bridge";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();
import "trix";
import "@rails/actiontext";
import "chartkick/chart.js"

// window.addEventListener("trix-file-accept", function (event) {
//   event.preventDefault();
//   alert("File attachment not supported!");
// });

Trix.config.blockAttributes.heading2 = {
  tagName: "h2",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

addEventListener("trix-initialize", (event) => {
  const { toolbarElement } = event.target;
  const h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]");
  h1Button.insertAdjacentHTML(
    "afterend",
    `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-2 flex justify-center" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">
    </button>
  `
  );
});

Trix.config.blockAttributes.heading3 = {
  tagName: "h3",
  terminal: true,
  breakOnReturn: true,
  group: false,
};

addEventListener("trix-initialize", (event) => {
  const { toolbarElement } = event.target;
  const h2Button = toolbarElement.querySelector("[data-trix-attribute=heading2]");
  h2Button.insertAdjacentHTML(
    "afterend",
    `
    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-3 flex justify-center" data-trix-attribute="heading3" title="Heading 3" tabindex="-2" data-trix-active="">
    </button>
  `
  );
});
